import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getAbsentData } from "../../app_redux/slice/absentData";
import axios from "axios";
import Cookies from "universal-cookie";
import { useFormik } from "formik";
import { markabsentSchema } from "../Schemas/markabsentschema";
import ConfirmAbsentModal from "../Profile/confirmAbsent";
import { useTranslation } from "react-i18next";

const initialValues = {
  startdate: "",
  enddate: "",
};

const AbsentDetailModal = ({ show, handleShow }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("tokenmusician");
  const { absentDataList, loading } = useSelector((state) => state.absent);
  const [showAbsentModal, setShowAbsentModal] = useState(false);
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab7, tab8, tab9, tab10 } =
    t("absent");
  const lng = i18n.language;

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: markabsentSchema,
      onSubmit: () => {
        showAbsent();
      },
    });
  useEffect(() => {
    dispatch(getAbsentData());
  }, []);
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleShowAbsent = () => {
    setShowAbsentModal(!showAbsentModal);
  };
  const showAbsent = () => {
    handleShowAbsent();
  };
  const handleDelete = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-musician-marked-absent`,
      data: {
        absence_id: id,
      },
    }).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(getAbsentData());
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="lg">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <form className="row mt-5 addNAme mx-width">
              <div className="col-sm-4 col-md-4 col-lg-4 mb-5">
                <label>{tab3}</label>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control"
                    name="startdate"
                    value={values.startdate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="absentFrom"
                  />
                  {errors.startdate && touched.startdate ? (
                    <p style={{ color: "red" }}>
                      {" "}
                      {lng == "en" ? errors.startdate : " Erforderlich"}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <img src="assets/images/calendarGrey.png" alt="" className="inputImgRight" /> */}
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 mb-5">
                <label>{tab4}</label>
                <div className="position-relative">
                  <input
                    type="date"
                    className="form-control"
                    name="enddate"
                    value={values.enddate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="absentTo"
                  />
                  {errors.enddate && touched.enddate ? (
                    <p style={{ color: "red" }}>
                      {lng == "en" ? errors.enddate : " Erforderlich"}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <img src="assets/images/calendarGrey.png" alt="" className="inputImgRight" /> */}
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 mb-5">
                <div className="position-relative">
                  <button className="btn add-new mt-3" onClick={handleSubmit}>
                    {tab1}
                  </button>
                </div>
              </div>
            </form>

            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <h5 className="text-center">{tab2}</h5>
                <table className="table storetable ">
                  <thead className="">
                    <tr>
                      <th scope="col">{tab6}</th>
                      <th scope="col">{tab5}</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {absentDataList?.length !== 0 ? (
                      absentDataList.map((res, i) => {
                        return (
                          <tr>
                            <td>
                              {formatDate(res.from_absence_date?.substr(0, 10))}{" "}
                              - {formatDate(res.to_absence_date?.substr(0, 10))}
                            </td>
                            <td
                              onClick={() => handleDelete(res.absence_id)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src="assets/images/delete.png"
                                width="25px"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p>{tab7}</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showAbsentModal ? (
        <ConfirmAbsentModal
          show={showAbsentModal}
          handleShow={handleShowAbsent}
          date={values.startdate}
          date2={values.enddate}
          tab8={tab8}
          tab9={tab9}
          tab10={tab10}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AbsentDetailModal;
