import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import OTPInput from "otp-input-react";
import Cookies from "universal-cookie";
import { Modal } from "react-bootstrap";
import ButtonLoader from "../Loader/loader";
import { useTranslation } from "react-i18next";

const OtpEmailModal = ({
  show,
  handleShow,
  email,
  setShowPhoneOtp,
  mobile,
  countryCode,
  load,
  setLoad,
}) => {
  const { i18n, t } = useTranslation();
  const { tab1, tab2, tab3, tab4, tab6 } = t("forms");
  const [OTP, setOTP] = useState("");
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
  };

  const handleSendOtpPhone = () => {
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/send-musician-otp-with-mobile-number`,
      data: {
        country_code: countryCode,
        phone_number: mobile,
      },
    })
      .then((res) => {
        if (res.data.message === "OTP Sent on Registered Phone Number") {
          // navigate('/otp-verification')
          setShowPhoneOtp(true);
          setLoad(false);
          handleShow();
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Invalid Phone Number! Retry.") {
          setLoad(false);
          toast.error("Invalid Phone Number.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleOtpVerify = (e) => {
    e.preventDefault();
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/verify-musician-otp-with-email`,
      data: {
        email: email,
        verify_otp: OTP,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoad(false);
          toast.success(tab6, {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleSendOtpPhone();
        }
      })
      .catch((error) => {
        // console.log("Error", error.response)
        setLoad(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleSendOtp = (e) => {
    setCounter(60);
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
      data: {
        email: email,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // navigate('/otp-verification')
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // if (error.response.data.message === 'Invalid Phone Number! Retry.') {
        //     toast.error("Invalid Phone Number.", {
        //         position: "top-right",
        //         autoClose: 4000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     })
        // }
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="xl">
        <Modal.Body className="p-0">
          <div>
            <section className="bg-image">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-7 ps-0 position-relative">
                    <img
                      src="assets/images/musicianBanner.png"
                      className="img-fluid w-100"
                      alt=""
                    />
                    <Link to="/">
                      {" "}
                      <img
                        src="assets/images/logo.png"
                        className="musiLogo"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-5 form-rightShift">
                    <div className="d-flex justify-content-end mt-2">
                      <img
                        src={
                          i18n.language === "en"
                            ? "assets/images/englishMobile.png"
                            : "assets/images/german.png"
                        }
                        style={{ width: "50px", height: "40px" }}
                        alt=""
                        className="img-fluid"
                        onClick={handleChangeLanguage}
                      />
                    </div>
                    <div className="d-table h-100">
                      <form className="form-style">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 text-center mb-4">
                            <h3 className="theme-color">{tab1}</h3>
                            <p>{tab2}</p>
                          </div>
                          <div className="form-group col-sm-12">
                            <div className="passcode-wrapper">
                              <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                placeholder="000000"
                              />
                            </div>
                          </div>
                          {/* <div className="col-sm-12 col-md-12 text-center mb-3">
                                                        <span className="fw-bold">00:{counter}</span>
                                                    </div> */}
                          <div className="col-sm-12 col-md-12 text-center mb-5">
                            {load ? (
                              <ButtonLoader view="btn btn-save w-75" />
                            ) : (
                              <button
                                className="btn btn-save w-75"
                                onClick={handleOtpVerify}
                              >
                                {tab3}
                              </button>
                            )}
                            {/* <Link to="/signup" className="btn btn-save w-75"><b>VERIFY</b></Link> */}
                          </div>

                          <div className="form-group mb-4 text-center">
                            <div>
                              <a
                                href="#"
                                className="forgot-pass p-0"
                                onClick={handleSendOtp}
                              >
                                {tab4}
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default OtpEmailModal;
