import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { useDispatch, useSelector } from "react-redux";
import { getTasknotification } from "../../app_redux/slice/tasknotification";
import { getEventnotification } from "../../app_redux/slice/eventnotification";
import { getAllnotification } from "../../app_redux/slice/allnotificationcount";
import { useTranslation } from "react-i18next";
import SwitchProfile from "./switchProfile";

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const cookie = new Cookies();
  const token = cookie.get("tokenmusician");
  const email = cookie.get("emailmusician");
  const phonenumber = cookie.get("phonenumbermusician");
  const countrycode = cookie.get("country_codemusician");
  const devicetoken = cookie.get("devicetokenmusician");
  const { taskcount, loading } = useSelector((state) => state.taskcount);
  const { eventcount } = useSelector((state) => state.eventcount);
  const { allchatcount } = useSelector((state) => state.allchatcount);
  const permissiontype = cookie.get("permission_musician");
  const [allBandleader, setAllBandleader] = useState([]);
  const [switchProfile, setSwitchProfile] = useState(false);
  const [creator, setCreator] = useState([]);
  const lang = cookie.get("googtrans");
  const domaintype = window.location.origin;
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab7, tab8, tab9, tab10 } =
    t("sidebarMusician");

  useEffect(() => {
    allBands();
  }, []);

  const handleSwitch = () => {
    setSwitchProfile(!switchProfile);
  };

  const handleNotification = () => {
    dispatch(getTasknotification());
    dispatch(getEventnotification());
    dispatch(getAllnotification());
  };
  const allBands = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-musician-bandleader-permission-band-list`,
    }).then((res) => {
      setCreator(res.data.data2);
      setAllBandleader(res.data.data);
    });
  };
  const handleBandSwitchSelf = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/switch-to-band-from-musician`,
      data: {
        band_id: creator[0]?.band_id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // cookie.remove('tokenmusician')
          // cookie.remove('emailmusician')
          // cookie.remove('firstnamemusician')
          // cookie.remove('lastnamemusician')
          // cookie.remove('profilepicmusician')
          // cookie.remove('phonenumbermusician')
          let authToken = res.data.data.auth_token;
          let user = res.data.data2.user_id;

          // window.location = `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}`
          window.location = domaintype?.includes(".com")
            ? `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}`
            : `https://bandleader.cleverbandassist.de/dashboard?token=${authToken}&user=${user}`;
          // window.location = `http://localhost:3000/dashboard?token=${authToken}&user=${user}`
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleBandSwitch = (id) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/switch-to-band-from-musician`,
      data: {
        band_id: id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // cookie.remove('tokenmusician')
          // cookie.remove('emailmusician')
          // cookie.remove('firstnamemusician')
          // cookie.remove('lastnamemusician')
          // cookie.remove('profilepicmusician')
          // cookie.remove('phonenumbermusician')
          let authToken = res.data.data.auth_token;
          let user = res.data.data2.user_id;

          window.location = domaintype?.includes(".com")
            ? `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}&user=${user}`
            : `https://bandleader.cleverbandassist.de/dashboard?token=${authToken}&user=${user}`;
          // window.location = `http://localhost:3000/dashboard?token=${authToken}&user=${user}`
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const updatetasknotification = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-musician-notification-status`,
      data: {
        notification_id: "",
        user_type: 1,
        is_read: 1,
        notification_type: "7",
      },
    }).then((res) => {
      dispatch(getTasknotification());
    });
  };
  const handleRedirect = (e) => {
    e.preventDefault();
    // window.location.href = "http://3.143.37.165/clever-band/build/dashboard";
    // return null;
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/switch-profiles`,
      data: {
        country_code: countrycode,
        phone_number: phonenumber,
        email: email,
        switch_to_user_type: 2,
        device_token: devicetoken ? devicetoken : "AS",
        device_type: "W",
      },
    })
      .then((res) => {
        if (res.data.message === "Profile Switched succesfully!") {
          cookie.remove("tokenmusician");
          cookie.remove("emailmusician");
          cookie.remove("firstnamemusician");
          cookie.remove("lastnamemusician");
          cookie.remove("profilepicmusician");
          cookie.remove("phonenumbermusician");
          let authToken = res.data.data.auth_token;
          // cookie.set('token', res.data.data.auth_token)
          // window.location = "http://localhost:3000/clever-band/build/dashboard" + "-" + authToken
          // window.location = "http://3.143.37.165/clever-band/build/dashboard"
          window.location = `https://bandleader.cleverbandassist.com/dashboard?token=${authToken}`;
          //window.location = `http://localhost:3000/clever-band/build/dashboard/?token=${authToken}`
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Account doesn't Exists!") {
          toast.error("Band doesn't Exists!", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      <div className="col-lg-3 store-sidebar admin-sidebar">
        <div className="shadow-part">
          <nav className=" collapse d-lg-block" id="sidebar">
            <ul className="list-unstyled mb-0">
              <li onClick={handleNotification}>
                <Link
                  to="/appointments"
                  className={pathname.includes("appointments") ? "active" : ""}
                >
                  <div>
                    <img
                      src="assets/images/appointments-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/appointments-menu-selected.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span>{tab1} </span>
                  <NotificationBadge
                    count={eventcount}
                    effect={Effect.SCALE}
                    style={{ backgroundColor: "white", color: "black" }}
                    className="mx-3 "
                  />
                </Link>
              </li>

              {permissiontype !== "3" ? (
                <li onClick={handleNotification}>
                  <Link
                    to="/setlist"
                    className={pathname.includes("setlist") ? "active" : ""}
                  >
                    <div>
                      <img
                        src="assets/images/setlist-menu.png"
                        className="img-fluid sidebar-icon inactive-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/setlist-menu.png"
                        className="img-fluid sidebar-icon active-icon"
                        alt=""
                      />
                    </div>
                    <span>{tab2} </span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {permissiontype !== "3" ? (
                <li onClick={handleNotification}>
                  <Link
                    to="/songs"
                    className={pathname.includes("songs") ? "active" : ""}
                  >
                    <div>
                      <img
                        src="assets/images/songs-menu.png"
                        className="img-fluid sidebar-icon inactive-icon"
                        alt=""
                      />
                      <img
                        src="assets/images/songs-menu.png"
                        className="img-fluid sidebar-icon active-icon"
                        alt=""
                      />
                    </div>
                    <span className="notranslate">{tab3} </span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              <li onClick={handleNotification}>
                <Link
                  to="/tasklist"
                  className={
                    pathname.includes("tasklist")
                      ? "active"
                      : pathname.includes("task-list-inprogress")
                      ? "active"
                      : pathname.includes("task-list-completed")
                      ? "active"
                      : ""
                  }
                  onClick={updatetasknotification}
                >
                  <div>
                    <img
                      src="assets/images/task-list.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/task-list.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span>{tab4}</span>
                  <NotificationBadge
                    count={taskcount}
                    effect={Effect.SCALE}
                    style={{ backgroundColor: "white", color: "black" }}
                    className="mx-2"
                  />
                </Link>
              </li>

              <li onClick={handleNotification}>
                <Link
                  to="/chat"
                  className={pathname.includes("chat") ? "active" : ""}
                >
                  <div>
                    <img
                      src="assets/images/chat-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/chat-menu.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span className="notranslate"> {tab5} </span>
                  <NotificationBadge
                    count={allchatcount}
                    effect={Effect.SCALE}
                    style={{ backgroundColor: "white", color: "black" }}
                    className="mx-3 "
                  />
                </Link>
              </li>

              <li onClick={handleNotification}>
                <Link
                  to="/profile"
                  className={pathname.includes("profile") ? "active" : ""}
                >
                  <div>
                    <img
                      src="assets/images/profile-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/profile-selected.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span>{tab6}</span>
                </Link>
              </li>

              <li onClick={handleNotification}>
                <Link
                  to="/gema-musician"
                  className={pathname.includes("gema-musician") ? "active" : ""}
                >
                  <div>
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span className="notranslate"> {tab7} </span>
                </Link>
              </li>
              <li onClick={handleNotification}>
                <Link to="/signup-bandleader">
                  <div>
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span> {tab8} </span>
                </Link>
              </li>
              <li onClick={handleSwitch}>
                <Link to="#">
                  <div>
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon inactive-icon"
                      alt=""
                    />
                    <img
                      src="assets/images/GEMA-menu.png"
                      className="img-fluid sidebar-icon active-icon"
                      alt=""
                    />
                  </div>
                  <span> {tab9} </span>
                </Link>
              </li>
              {/* <li onClick={allBands}><Link to="#">
                                <div >
                                    <img src="assets/images/GEMA-menu.png" className="img-fluid sidebar-icon inactive-icon" alt="" />
                                    <img src="assets/images/GEMA-menu.png" className="img-fluid sidebar-icon active-icon" alt="" />
                                </div>
                                <li className="nav-item ">
                                    <div className="dropdown">
                                        <button className="btn nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span >{tab9}</span>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <p className='dropdown-item notranslate' onClick={handleBandSwitchSelf}>{creator[0]?.band_name}</p>
                                            {allBandleader?.length !== 0 ? allBandleader.map((res, i) => {
                                                return <p className="dropdown-item notranslate" onClick={() => handleBandSwitch(res.band_id)}>{res.band_name}</p>
                                            }) : <p className="dropdown-item" ></p>}
                                        </div>
                                    </div>

                                </li>
                            </Link></li> */}
            </ul>
          </nav>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {switchProfile ? (
        <SwitchProfile
          show={switchProfile}
          handleShow={handleSwitch}
          tab10={tab10}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Sidebar;
